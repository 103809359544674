import { useEffect } from 'react';
import { useRouter } from 'next/router';

import {
  Card, Nav, NavItem, CardBody,
} from 'reactstrap';

import WithActions from '@/engine/decorators/with-actions';
import WithState from '@/engine/decorators/with-state';

import Button from '@/components/button';
import Icon from '@/components/icon';
import Loading from '@/components/loading';

import {
  VIEW_BUDGET,
  VIEW_GALLERY,
  VIEW_TRADE_TRACKER,
  VIEW_DOSSIER,
} from '../../constants';

import PropertyHeading from './property-heading';
import PropertyBudget from './property-budget';
import { WorkTracker } from '@/modules/work-tracker';
import PropertyGallery from '../gallery/property-gallery';
import PropertyDossier from '../dossier';

const Property = ({ property, view, publish }) => {
  const router = useRouter();
  const { id } = router.query;

  useEffect(() => {
    if (id) {
      publish({ event: 'load', id });
    }
  }, [id]);

  const openBudget = (e) => {
    e.preventDefault();
    publish({ event: 'view', view: VIEW_BUDGET });
  };

  const openGallery = (e) => {
    e.preventDefault();
    publish({ event: 'view', view: VIEW_GALLERY });
  };

  const openTradeTracker = (e) => {
    e.preventDefault();
    publish({ event: 'view', view: VIEW_TRADE_TRACKER });
  };

  const openDossier = (e) => {
    e.preventDefault();
    publish({ event: 'view', view: VIEW_DOSSIER });
  };

  const renderView = () => {
    switch (view) {
      case VIEW_TRADE_TRACKER:
        return (
          <WorkTracker />
        );
      case VIEW_GALLERY:
        return (
          <PropertyGallery id="property-gallery" />
        );
      case VIEW_DOSSIER:
        return (
          <PropertyDossier id="property-dossier" propertyId={property.id} />
        );
      case VIEW_BUDGET:
      default:
        return (
          <PropertyBudget id="property-budget" property={property} />
        );
    }
  };

  const renderProperty = () => {
    return (
      <Card className="card-raised mw-70 m-auto card">
        <PropertyHeading property={property} />
        <Nav tabs>
          <NavItem active={view === VIEW_BUDGET}>
            <span
              className="btn as-link"
              onClick={openBudget}
              title="View the budget for this project"
            >
              <Icon icon="track_changes" size="md" />
              {' '}
              Budget
            </span>
          </NavItem>
          <NavItem active={view === VIEW_GALLERY}>
            <span
              className="btn as-link"
              onClick={openGallery}
              title="View all the images of this project"
            >
              <Icon icon="photo_library" size="md" />
              {' '}
              Gallery
            </span>
          </NavItem>
          <NavItem active={view === VIEW_TRADE_TRACKER}>
            <span
              className="btn as-link"
              onClick={openTradeTracker}
              title="View statistics for this project"
            >
              <Icon icon="attach_money" size="md" />
              {' '}
              Trade Tracker
            </span>
          </NavItem>
          {/* <NavItem active={view === VIEW_DOSSIER}>
            <span
              className="btn as-link"
              onClick={openDossier}
              title="View documents for this property"
            >
              <Icon icon="article" size="md" />
              {' '}
              Dossier
            </span>
          </NavItem> */}
        </Nav>
        <CardBody>
          {renderView()}
        </CardBody>
      </Card>
    );
  };

  const renderLoading = () => {
    return (
      <Loading />
    );
  };

  return (
    <div className="property">
      <div className="back-button-top-container back-button-top-container--no-collapse">
        <Button
          id="property-back"
          swatch
          color="secondary"
          className="back-button-top"
          size="sm"
        >
          Back
        </Button>
      </div>
      {property?.id ? renderProperty() : renderLoading()}
    </div>
  );
};

export default WithState(WithActions(Property));
