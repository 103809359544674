import { propEq } from 'rambda';
import Router from 'next/router';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import Api from '@/engine/utils/api';
import adminUsersDataStore from '@/data/users/admin-users-data-store';

const adminUserAction = actionDispatcher.subscribe('adminUser');
const adminUserBackAction = actionDispatcher.subscribe('admin-user-details-back');

const openUser = (data) => {
  const user = data.data;
  adminUsersDataStore.update(user);
  Router.push(`/admin/users/${user.id}`);
};

const getUserDetails = ({ userId }) => {
  return Api.get(`/api/admin/users/${userId}`);
};

const openUserUpdates = adminUserAction.filter(propEq('event', 'openUser'));

const openUserUnsub = openUserUpdates.flatMap(getUserDetails).onValue(openUser);
const backUnSub = adminUserBackAction.onValue(() => Router.push('/admin/users'));

export const OpenUserUpdates = openUserUpdates;

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      openUserUnsub();
      backUnSub();
    });
  }
}
