import AnonymousLayout from '@/layouts/anonymous';

import TermsPrint from '../components/terms-print';

const pageProps = {
  id: 'termsPrint',
};

const TermsPrintPage = () => (
  <AnonymousLayout Component={TermsPrint} componentProps={pageProps} />
);

export default TermsPrintPage;
