import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
} from 'reactstrap';

import Button from '@/components/button';
import LoginLogo from '@/components/login-logo';
import actionDispatcher from '@/engine/actions/action-dispatcher';

const renderEmailNotVerifiedMessage = () => {
  return (
    <Container fluid>
      <Row>
        <Col xs="12" className="d-flex align-items-center justify-content-center">
          <LoginLogo />
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6" lg="4" className="ml-auto mr-auto mb-2">
          <h3>Register a New Account</h3>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6" lg="4" className="ml-auto mr-auto mb-2">
          <div className="alert alert-success" role="alert">
            Hang tight, you will receive an email with a link to verify your email and activate your account.
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6" lg="4" className="ml-auto mr-auto">
          <hr className="w-100" />
          <Button
            id="logout"
            color="secondary"
            type="submit"
            size="sm"
            className="d-flex ml-auto mr-auto"
          >
            Return to Login
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

const AccountInactive = ({ isTrial, emailVerified }) => {
  if (!emailVerified) {
    return renderEmailNotVerifiedMessage();
  }

  const renderInActiveMessage = () => {
    return isTrial ? 'Your free trial has expired.' : 'Your subscription has expired';
  };

  const openTerms = (e) => {
    e.preventDefault();
    actionDispatcher.dispatch({ id: 'terms-modal-open', data: {} });
  };

  return (
    <form>
      <Container fluid>
        <Row>
          <Col xs="12" className="d-flex align-items-center justify-content-center">
            <LoginLogo />
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6" lg="4" className="ml-auto mr-auto">
            <div className="alert alert-warning">{renderInActiveMessage()}</div>
          </Col>
        </Row>
        <Card className="mw-30 m-auto card-raised">
          <CardBody>
            <Row>
              <Col xs="12" className="ml-auto mr-auto">
                <div className="h3">Buy now to:</div>
                <ul>
                  <li>Manage your properties</li>
                  <li>Share property info with others</li>
                  <li>Manage your renovation budgets</li>
                  <li>Keep track of important documents for each property</li>
                </ul>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs="12" className="ml-auto mr-auto">
                <hr className="w-100" />
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <div className="h5"><strong>Monthly</strong></div>
                <ul>
                  <li>Pay as you go</li>
                  <li>Cancel any time</li>
                </ul>
              </Col>
              <Col xs="6">
                <div className="h5"><strong>Annually</strong></div>
                <ul>
                  <li>Save 2 months</li>
                  <li>Receive free updates for a year</li>
                </ul>
              </Col>
            </Row>
            <Row className="mt-3 mb-3">
              <Col xs="6" className="text-center">
                <div className="h5"><strong>$5.00 monthly</strong></div>
                <Button
                  id="subscribe"
                  color="primary"
                  type="submit"
                  size="lg"
                  className="w-100"
                  actionMetaData={{ annual: false }}
                >
                  Purchase Monthly
                </Button>
              </Col>
              <Col xs="6" className="text-center">
                <div className="h5"><strong>$50.00 yearly</strong></div>
                <Button
                  id="subscribe"
                  color="primary"
                  type="submit"
                  size="lg"
                  className="w-100"
                  actionMetaData={{ annual: true }}
                >
                  Purchase Annually
                </Button>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs="12" className="ml-auto mr-auto text-center">
                <hr className="w-100" />
                By continuing, I agree to the&nbsp;
                <a
                  className="btn btn-secondary"
                  href="/print/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ paddingLeft: '0rem' }}
                  onClick={openTerms}
                >
                  Terms Of Use
                </a>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <div className="mt-2" />
      </Container>
    </form>
  );
};

export default AccountInactive;
