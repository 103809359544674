import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import WithState from '@/engine/decorators/with-state';

import Button from '../button';

import Terms from '../terms';

const openTerms = () => {
  window.open('/print/terms', '_blank');
};

const TermsModal = ({
  isVisible,
  className,
}) => {
  return (
    <Modal isOpen={isVisible} className={className}>
      <ModalHeader>RENOTRACKER - License Terms Of Use</ModalHeader>
      <ModalBody>
        <Terms hideTitle />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" id="terms-modal-close" onClick={openTerms}>Print</Button>
        <Button color="primary" id="terms-modal-close">Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default WithState(TermsModal);
