/* eslint-disable max-len */
const terms = {
  title: 'RENOTRACKER - License Terms Of Use',
  sections: [
    {
      title: 'LICENSE',
      items: [
        {
          title: 'Grant of licence',
          content: 'MPL grants to the Licensee a non-exclusive, non-transferable, personal licence to use the Software and Initial Data in accordance with the normal operating procedures for the Software as notified by MPL from time to time; and',
          subItems: [
            'use the Software for the Permitted Use subject to the current payment of any applicable Fee.',
          ],
        },
        {
          title: 'Delivery and installation',
          content: 'MPL will make the Software available via its website www.renotracker.co.nz for the Licensee to access as soon as reasonably possible following the Commencement Date.',
        },
        {
          title: 'Permitted users',
          content: 'The Licensee will limit access to the Software, Initial Data and Documentation, solely to:',
          subItems: [
            'itself, where the Licensee is an individual; or',
            'the Licensee’s directors, employees or agents who have a need to use the Software for the Permitted Use, and subject to the requirements of clause 4.2. The Licensee will be liable for all acts or omissions of such directors, employees and agents as if they were the acts or omissions of the Licensee.',
          ],
        },
        {
          title: 'Restrictions on use',
          content: 'The Licensee may not:',
          subItems: [
            'translate, reverse engineer, reverse assemble or decompile the whole or any part of the Software or directly or indirectly allow or cause a third party to do so, except where expressly permitted under applicable law;',
            'copy or reproduce the Software by any means or in any form without MPL’s prior written consent; or ',
            'modify, adapt or alter the Software or merge all or any part of the Software with any other software.',
          ],
        },
        {
          title: 'Ownership of modifications',
          content: 'The Software as modified or altered remains the exclusive property of MPL (and/or its licensors or suppliers) whether modified by the Licensee, MPL or a third party. The Licensee will, if required by MPL, assign to MPL (and/or its licensors or suppliers) all the Licensee’s rights, title and interest in all Intellectual Property Rights arising out of any modifications or alterations to the Software.',
        },
        {
          title: 'Licensee responsible for security',
          content: 'The Licensee will be solely responsible for the use, access to, supervision, management and control of the Software, Initial Data and Documentation and will ensure that the Software, Initial Data and Documentation is protected at all times from misuse, damage, theft, destruction or any form of unauthorised use.',
        },
      ],
    },
    {
      title: 'SUPPORT AND MAINTENANCE',
      items: [
        {
          title: 'Support',
          content: 'In consideration of the Subscription Fee, MPL shall provide to the Licensee, the Basic Support Services from the Commencement Date.',
        },
        {
          title: 'Reporting defects',
          content: 'The Licensee may report suspected defects or errors in the Software using the online help at by emailing MPL at help@renotracker.co.nz. The Licensee will give to MPL a documented example of the alleged defect or error when making such report, together with any information reasonably required by MPL.',
        },
        {
          title: 'Assistance',
          content: 'The Licensee shall provide such information, facilities, assistance or access as may be reasonably requested by MPL in order to provide the Basic Support Services. MPL does not warrant that it will respond to any report within any particular timeframe or that it will solve or remedy all reported defects or errors.',
        },
        {
          title: 'Excluded services',
          content: 'The Basic Support Services do not include the correction of errors or defects caused by:',
          subItems: [
            'operation of the Software;',
            'modifications, alterations or enhancements to the Software (other than those authorised by MPL);',
            'computer programs (other than the Software);',
            'any other hardware, equipment, accessories, attachments, consumables or other items of the Licensee;',
            'any incorrect configuration by the Licensee;',
            'any virus, worm, Trojan horse, other malicious code, or any software program not authorised by MPL;',
            'operator errors; or ',
            'any one-on-one or personal training or support',
          ],
          footNote: 'and MPL will be entitled to charge an additional fee in accordance with MPL then current rates, for providing any additional services arising from such circumstances. Any other services outside the scope of the Basic Support Services agreed to be provided by MPL to the Licensee will be provided at an additional fee charged to the Licensee in accordance with MPL then current rates.',
        },
        {
          title: 'Updates',
          content: 'MPL may from time to time provide the Licensee with general patches, bug fixes, updates, or new versions of the Software as part of the Basic Support Services, however MPL is not obliged to do so. Any such patches, bug fixes, updates or new releases shall be licensed to the Licensee under the terms and conditions of this Agreement. MPL shall not be obliged to provide the Basic Support Services in respect of any update or new version of the Software that has been superseded by more than two subsequent updates or new version.',
        },
      ],
    },
    {
      title: 'FEES',
      items: [
        {
          title: 'Fees',
          subItems: [
            'In consideration of the licence under clause 1.1 and providing the Basic Support Services, the Licensee will pay the Subscription Fee in advance on or before the Commencement Date and each anniversary of the Commencement Date.',
            'MPL may increase the Subscription Fee on any anniversary of the Commencement Date by giving the Licensee not less than 30 days’ prior written notice of such increase.',
            'Without prejudice to any other rights or remedies MPL may have, MPL may suspend the license of the Software, Initial Data and Documentation and the Licensee\'s access to the Software, Initial Data and Documentation if the Licensee has not paid the Subscription Fee by the relevant due date.',
          ],
        },
        {
          title: 'Payment terms',
          content: 'All sums due to MPL under this Agreement:',
          subItems:[
            'will be paid in New Zealand dollars without deduction or set-off of any kind; and ',
            'will be paid on or before the required payment date under clause 3.1, failing which MPL may charge interest on any outstanding amount on a daily basis at a rate of 12% per annum from the Commencement Date until the date of actual payment.',
          ],
        },
      ],
    },
    {
      title: 'INTELLECTUAL PROPERTY RIGHTS AND CONFIDENTIALITY',
      items: [
        {
          title: 'Ownership',
          content: 'The Licensee acknowledges that',
          subItems: [
            'the Software, Initial Data and Documentation comprises concepts, formats, information and materials that are confidential and owned by MPL (and/or its licensors or suppliers); and ',
            'the Software, Initial Data and Documentation and any and all Intellectual Property Rights in them, are and will remain the exclusive property of MPL (or its licensors or suppliers), and the Licensee will not during or at any time after the termination of this Agreement in any way question or dispute the ownership by MPL (or its licensors or suppliers) of the Software, Initial Data and Documentation and all Intellectual Property Rights in them',
          ],
        },
        {
          title: 'Confidentiality obligations',
          content: 'The Licensee will maintain as secret and confidential, at all times, all Confidential Information. The Licensee agrees to:',
          subItems: [
            'respect MPL’s rights to the Confidential Information;',
            'use the Confidential Information solely for the Permitted Use;',
            'disclose or provide Confidential Information only to those of the Licensee’s directors, employees and agents to whom, and to the extent that, such disclosure is reasonably necessary for the Permitted Use; and',
            'keep the Confidential Information safe and secure from unauthorised access or use.',
          ],
        },
        {
          title: 'Exceptions to obligations',
          content: 'The provisions of clause 4.2 (a) to (c) will not apply to Confidential Information that the Licensee can conclusively demonstrate by written evidence:',
          subItems: [
            'is independently developed by the Licensee without breaching any of the Licensee’s obligations under this Agreement and without use of any other Confidential Information;',
            'is subsequently disclosed to the Licensee, without any obligations of confidence, by a third party who has not derived it from MPL;',
            'is or becomes generally available to the public through no act or default of the Licensee; or',
            'is required to be disclosed by law.',
          ],
        },
        {
          title: 'Disclosure to employees',
          content: 'The Licensee will ensure that any person to whom the Licensee discloses Confidential Information in accordance with clause 4.2(c):',
          subItems: [
            'is made aware of and subject to the Licensee’s obligations under clause 4.2; and',
            'has entered into written undertakings of confidentiality in favour of the Licensee that are at least as restrictive as those set out in clauses 4.2 and 4.3 and that apply to the Confidential Information.',
          ],
          footNote: 'The Licensee will be responsible to MPL for any unauthorised disclosure of Confidential Information by such persons as if the disclosure were a disclosure by the Licensee under this clause 4.',
        },
        {
          title: 'Misuse or breach',
          content: 'The Licensee will notify MPL in writing immediately upon becoming aware of any potential, threatened or actual misuse of the Confidential Information or breach of the Licensee\'s obligations under this clause 4. The Licensee will co-operate with MPL in preventing or limiting such misuse or breach, at the cost of MPL.',
        },
        {
          title: 'Equitable relief',
          content: 'The Licensee acknowledges that any breach of this clause 4 by the Licensee may cause MPL irreparable harm for which damages would not be an adequate remedy. In addition to any other remedy available to it, MPL may seek equitable relief (including injunctive relief or specific performance) against any breach or threatened breach of this clause 4 by the Licensee.',
        },
        {
          title: 'Infringement',
          content: 'If the Licensee learns of any third party claim, infringement or threatened infringement relating to the Software, it will promptly notify MPL giving full particulars of such circumstances, and will make no comment or admission to any such third party. MPL (and/or its licensors or suppliers) will have full control over all proceedings and negotiations relating to any such claim, infringement or threatened infringement, and the Licensee will, at the request and cost of MPL, co-operate fully with MPL (and/or its licensors or suppliers) in any action, claim or proceedings brought or threatened in respect of the Software.',
        },
      ],
    },
    {
      title: 'WARRANTY AND LIABILITY',
      items: [
        {
          title: 'Software warranty',
          subItems: [
            'When notifying a defect the Licensee will (so far as it is able) provide MPL with a documented example of such defect. MPL will investigate the perceived defect and will, upon the verification of the existence of the defect, use commercially reasonable endeavours to rectify such defect without additional charge to the Licensee',
            'The obligation under clause 5.1(a) will be MPL’s sole obligation, and the Licensee’s sole remedy in the event of any failure of the Software at any time. MPL will not be liable under this clause 5.1 to the extent that a defect is caused or contributed to by any of the things listed in clause 2.4(a) to (h). ',
            'MPL make no warranty that the Software will meet the Licensee’s requirements, that the Software will be error-free or that its use will be uninterrupted. The Licensee acknowledges and agrees that the existence of such errors will not constitute a breach of this Agreement',
          ],
        },
        {
          title: 'No other warranties',
          content: 'The warranty in clause 5.1 is the only warranty provided by MPL in respect of the Software and apart from any condition, warranty or liability imposed by statute or common law that cannot be excluded, restricted or modified under applicable law, MPL:',
          subItems: [
            'excludes all other warranties in relation to the Software, either express or implied, including but not limited to all implied warranties relating to quality, fitness for any particular purpose or ability to achieve a particular result; and',
            'in no way indemnifies the Licensee for or against any loss, damages or expense arising from the use of the Software under this Agreement',
          ],
        },
        {
          title: 'Exclusion of liability',
          content: 'Without limiting the effect of clauses 5.2, 5.4 and 5.5 in no event will MPL be liable (whether in contract, tort including negligence, or otherwise) to the Licensee for:',
          subItems: [
            'any indirect or consequential loss, damage, cost or expense of any kind; or',
            'any loss of revenue and/or profit, loss of production, loss of anticipated savings, loss or corruption of data or wasted management time',
          ],
          footNote: 'even if such loss, damage, cost or expense was reasonably foreseeable by MPL.',
        },
        {
          title: 'Aggregate limit',
          content: 'Subject to clauses 5.3 and 5.5, MPL’s maximum liability to the Licensee arising out of all claims under or in relation to this Agreement or relating to the Software will not in any circumstances exceed NZ$100.',
        },
      ],
    },
    {
      title: 'TERM AND TERMINATION',
      items: [
        {
          title: 'Term',
          content: 'This Agreement will come into effect on the Commencement Date and will continue in full force and effect without limit of period until terminated by either party under clauses 6.2 or 6.3.',
        },
        {
          title: 'Termination before anniversary',
          content: 'Either party may terminate this Agreement on any anniversary of the Commencement Date, provided that party has giving the other party written notice of such termination at least 30 days before the relevant anniversary of the Commencement Date.',
        },
        {
          title: 'Termination on breach',
          content: 'Without prejudice to any other right or remedy it may have, either party may immediately terminate this Agreement at any time by notice in writing to the other party if:',
          subItems: [
            'the other party is in material breach of this Agreement and the material breach is not remedied within 30 days of the other party receiving notice specifying the material breach and requiring its remedy;',
            'the other party ceases or threatens to cease to carry on all or substantially all of its business or operations, becomes bankrupt or insolvent, is unable to pay its debts as they fall due, enters into a general assignment of its indebtedness or a scheme of arrangement or composition with its creditors, or takes or suffers any similar or analogous action in consequence of debt; or',
            'a trustee, manager, administrator, administrative receiver, receiver, inspector under any legislation or similar officer is appointed in respect of any part of the other party’s assets or business, or an order is made or a resolution is passed for the liquidation of the other party',
          ],
        },
        {
          title: 'Consequences of termination',
          content: 'On termination of this Agreement for any reason whatsoever',
          subItems: [
            'the Licensee will cease to use or have access to the Software, Initial Data and Documentation;',
            'the Licensee will at its own cost and expense, destroy all copies of the Confidential Information and Software, Initial Data and Documentation in its possession or under its control; and',
            'no part of the Subscription Fee will be refunded, paid or credited back to the Licensee, except where the Licensee has terminated this Agreement under clause 6.3.',
          ],
        },
        {
          title: 'Clauses surviving',
          content: ' Clauses 1.4, 1.4, 3, 4, 5.2 to 5.5, 6.4, 6.5, 7 and any other clauses intended to so do will survive termination of this Agreement. Termination of this Agreement will not affect the accrued rights or obligations of the parties.',
        },
      ],
    },
    {
      title: 'GENERAL',
      items: [
        {
          title: 'Force majeure',
          content: 'MPL will not be liable to the Licensee for any breach or failure to perform any of its obligations under this Agreement where such breach or failure is caused by war, civil commotion, hostility, act of terrorism, strike, lockout, other industrial act, weather phenomena or other act of God, governmental regulation or direction, or any other cause beyond MPL’s reasonable control whether similar to any of the foregoing or not',
        },
        {
          title: 'Variations',
          content: 'MPL may make modifications or variations to this Agreement at any time by giving the Licensee notice to the e-mail address or physical address provided in the Licensee\'s online registration form (or such other address subsequently notified to us by the Licensee). Such modifications or variations will be effective immediately (unless expressly stated otherwise).',
        },
        {
          title: 'Severability',
          content: 'If any provision of this Agreement is held invalid, unenforceable or illegal for any reason, this Agreement will remain otherwise in full force apart from such provisions which will be deemed deleted.',
        },
        {
          title: 'Entire agreement',
          content: 'This Agreement constitutes the entire agreement between the parties and supersedes all prior representations, agreements, statements and understandings between the parties, whether verbal or in writing. The Licensee acknowledges that it does not enter into this Agreement in reliance on any representation, warranty, term or condition not expressly provided in this Agreement',
        },
        {
          title: 'Further action',
          content: 'Each party agrees to execute, acknowledge and deliver such further instruments, make all applications and do all further similar acts, as may be necessary or appropriate to carry out the purposes and intent of this Agreement.',
        },
        {
          title: 'No assignment',
          content: 'The Licensee may not assign, transfer, novate, sub-contract or sub-license the whole or any part of its rights or obligations under this Agreement without first obtaining the written consent of MPL',
        },
        {
          title: 'No waiver',
          content: 'No failure or delay on the part of a party to exercise any right or remedy under this Agreement will be construed or operate as a waiver',
        },
        {
          title: 'Governing law',
          content: 'This Agreement will be governed and construed in accordance with the law of New Zealand and the parties submit to the non-exclusive jurisdiction of the courts of New Zealand.',
        },
      ],
    },
    {
      title: 'DEFINITIONS AND INTERPRETATION',
      items: [
        {
          title: 'Definitions',
          content: 'In this Agreement the following words will have the following meanings:',
          definitions: [
            {
              term: 'agreement',
              content: 'means this licence agreement;',
            },
            {
              term: 'Subscription Fee',
              content: 'means the monthly fee payable in advance for the licensing of the Software and the Basic Support Services;',
            },
            {
              term: 'Basic Support Services',
              content: 'means the following support and maintenance services in respect of the Software:',
              subItems: [
                'the provision of online help to assist the Licensee with errors, defects, problems or queries relating to the Software; ',
                'the provision of online FAQ, instructions and help documentation deemed necessary at MPL’s entire discretion;',
                'correcting defects or errors in the Software by making available patches and bug fixes from time to time at MPL’s entire discretion; and',
                'enhancing, updating and modifying the Software by making available new releases, new versions, or updates from time to time at MPL’s entire discretion; ',
              ],
            },
            {
              term: 'Commencement Date',
              content: 'means the date upon which the Subscription Fee is received by MPL;',
            },
            {
              term: 'Confidential Information',
              content: 'means all information of any kind, whether or not it is in tangible or documentary form and whether or not marked or identified as being confidential, that relates to MPL, its licensors or their business affairs and includes information relating to the design, specification and content of the Software;',
            },
            {
              term: 'MPL',
              content: 'means Myproperties 2014 Limited, a New Zealand company;',
            },
            {
              term: 'Documentation',
              content: 'means any user guides, manuals, spreadsheets, handbooks, data files, tables, flowcharts, maintenance libraries, educational materials and other related documents (including those in electronic form) supplied or made available by MPL for use with the Software;',
            },
            {
              term: 'Initial Data',
              content: 'means any data or information pre-loaded into the Software by MPL when the Software is first used on a Designated Device;',
            },
            {
              term: 'Intellectual Property Rights',
              content: 'means all industrial and intellectual property rights whether conferred by statute, at common law or in equity, including, but not limited to, all copyright and similar rights that may subsist in works or other subject matter, rights in relation to databases, rights in relation to inventions (including all patents and patent applications) and rights in relation to trade secrets, know-how, trade marks, business names and domain names; ',
            },
            {
              term: 'Licensee',
              content: 'means the person(s) or body corporate who completes the online registration and payment;',
            },
            {
              term: 'Permitted Use',
              content: 'means the use of the Software for the purposes of the Licensee\'s business operation and/or personal affairs in respect of property investment;',
            },
            {
              term: 'Software',
              content: 'means RenoTracker, including (where applicable) any patches, bug fixes, enhancements, modifications, updates, new releases or new versions.',
            },
          ],
        },
        {
          title: 'Interpretation',
          content: 'In this Agreement:',
          subItems: [
            'references to a party include that party’s successors, executors, administrators and permitted assignees;',
            'references to clauses are to those clauses in this Agreement;',
            'references to a person include an individual, firm, company, corporation or unincorporated body of persons, any public, territorial or regional authority, any government, and any agency of any government or of any such authority; and',
            'an obligation not to do anything includes an obligation not to suffer, permit or cause that thing to be done.',
          ],
        },
      ],
    },
  ],
};

const renderSectionItems = (sectionId, section) => {
  let sectionItemId = 0;
  return (
    section.items.map((item) => {
      sectionItemId += 1;
      let sectionAlphaId = 0;
      const colon = item.content ? ':' : '';
      return (
        <div>
          <p>
            <b>{`${sectionId}.${sectionItemId} ${item.title}${colon}`}</b>
            {item.content && <span>{` ${item.content}`}</span>}
          </p>
          {item.subItems && (
            <ul style={{ listStyleType: 'none', paddingInlineStart: '30px' }}>
              {item.subItems.map((subItem) => {
                sectionAlphaId += 1;
                return <li>{`(${String.fromCharCode(96 + sectionAlphaId)}) ${subItem}`}</li>;
              })}
            </ul>
          )}
          { item.definitions && (
            item.definitions.map((definition) => {
              let definitionId = 0;
              return (
                <div>
                  <b>{definition.term}</b>
                  <span>{definition.content}</span>
                  {definition.subItems && (
                    <ul style={{ listStyleType: 'none', paddingInlineStart: '30px' }}>
                      {definition.subItems.map((subItem) => {
                        definitionId += 1;
                        return (
                          <li>{`(${String.fromCharCode(96 + definitionId)}) ${subItem}`}</li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              );
            })
          )}
          {item.footNote && <p>{item.footNote}</p>}
        </div>
      );
    })
  );
};

const renderSections = () => {
  let sectionId = 0;
  return terms.sections.map((section) => {
    sectionId += 1;
    return (
      <div key={sectionId}>
        <h3>{`${sectionId} ${section.title}`}</h3>
        <hr />
        {renderSectionItems(sectionId, section)}
      </div>
    );
  });
};

const Terms = ({ hideTitle }) => {
  return (
    <div>
      {!hideTitle && (<h1>RENOTRACKER - License Terms Of Use</h1>)}
      {renderSections()}
    </div>
  );
};

export default Terms;
