import { always } from 'rambda';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import stateStore from '@/engine/stores/state-store';

const openAction = actionDispatcher.subscribe('terms-modal-open');
const closeAction = actionDispatcher.subscribe('terms-modal-close');

const stateUnsub = openAction
  .map(always({ isVisible: true }))
  .merge(closeAction.map(always({ isVisible: false })))
  .doLog('state-changes')
  .onValue(stateStore.Publish('common', 'terms-modal'));

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('unloading property-print-store');
      stateUnsub();
    });
  }
}
